import React, {useState, useRef, useEffect} from "react"
import { connect } from "react-redux"
import Formulario from '../Contact.jsx'
import Card from '../property-card'

import {getDescription,descriptionIsNull} from '../../helpers/helper.rendering'
import {getTags,getBluePrints, getFakeAddres, getLocation,getTour, getLastVideo, getOperations, getType, getCustomTags} from '../../helpers/helper.properties'
import Breadcrumbs from "../breadcrumbs";

import Map from '../map'

import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';

import Loading from '../../components/Loading'
import OwlCarousel from 'react-owl-carousel3';
import FilesPro from "../Emprendimiento/files-pro.jsx"


const options = {
    settings: {
        overlayColor: "rgba(0, 0, 0, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
    },
    buttons: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        iconColor: "rgba(255,255,255, 1)",
    },
    caption: {
        captionColor: "#000",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
    }
};

const options_slider = {
    // loop:true,
    // smartSpeed:1000,
    // stagePadding:10,
    center:false,
    // autoplay:true,
    margin:15,
    items:3,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        992: {
            items: 3,
        },
    },
}
const option = {
    loop:true,
    smartSpeed:1000,
    stagePadding:10,
    center:true,
    margin:10,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,

        },
        1200: {
            items: 3,

        }
    },
}

const About = ({property,similar_properties,loading,dispatch}) => {
    const refery = useRef()
    const [charMax,setCharMax] = useState(99999)
    const { openLightbox, closeLightbox } = useLightbox()
    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            global_email
            global_phone
            branch_office{
              contact {
                phone
                mail
                whatsapp
              }
            }
        }
    }`)

    const slider_similar = useRef()

    const checkSimilar = (property,properties) => {
        if(properties){
            return properties.filter(p => p.id !== property.id)
        }
    }


    return (
        <section class="ficha-about prop">
            <div class="container-fluid media-section">
                <div class="row no-gutters pb-5">
                    <div className="col-12">
                        {
                            property ?
                             <Breadcrumbs
                                props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: getOperations(property)[0] , route:   "/" + getOperations(property)[0].toLowerCase(), location: ""},
                                    { name: getType(property) , route:   "/" + getOperations(property)[0].toLowerCase() + "/" + getType(property).toLowerCase(), location: ""},
                                    { name: getFakeAddres(property) , route:   "", location: ""},
                                ]}
                                />
                                :''
                        }
                    </div>
                    <div class="col-lg-7 my-5 my-lg-0">
                        <h4 class="mb-5">Descripción de la propiedad.</h4>
                        <p class="description-ficha">{property && 
                            getDescription(property,charMax).map((description,index) => (
                               <>
                                {description}
                                <br />
                               </>
                            ))
                        }
                        {/* {1000 < property?.description?.length &&
                        <>
                            {charMax === 1000 && !descriptionIsNull(property) && <span class="d-block text-right text-expand"><a onClick={() => setCharMax(9999)}>Ver más</a></span>}
                            {charMax === 9999 && <span class="d-block text-right text-expand"><a onClick={() => setCharMax(1000)}>Ver menos</a></span>}
                        </>
                        } */}
                        </p>
                    </div>
                    <div class={"col-lg-4 offset-lg-1 "}>
                        <div className={(getTags(property).length > 0 ? '' : 'd-none') }>
                            <h3 class="mb-5 mt-5 mt-lg-auto ">DETALLES</h3>
                            <div class="row no-gutters amenities">
                                {property && getTags(property).map((tag,index) => (
                                    <div class="col-6 mb-3 item align-items-center d-flex">
                                        <i class="icon-checked"></i>
                                        <p>{tag.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={"mt-lg-4 " + (getCustomTags(property).find(tag => tag.group_name?.toLowerCase() === 'especales')?.length > 0 ? '' : 'd-none') }>
                            <h3 class="mb-4 mt-5 mt-lg-auto ">DETALLES ESPECIALES</h3>
                            <div class="row no-gutters amenities">
                                {property && getCustomTags(property).map((tag,index) => (
                                    tag.group_name?.toLowerCase() === 'especiales' &&
                                    <div class="col-6 mb-3 item align-items-center d-flex">
                                        <i class="icon-checked"></i>
                                        <p style={{lineHeight:"1"}}>{tag.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
               
                </div>
                <FilesPro files={property.files} />
            </div>
            <div class="ubicacion-main pt-lg-5 pt-5">
                <div className="container-fluid mt-4">
                    <div className="row">
                        <div class="col-lg-2">
                            <h4 class="section-name">Ubicación</h4>
                        </div>
                        <div class="col-lg-10">
                            <p class="addres-map">{getFakeAddres(property)}.</p>
                            <p class="addres-map">{getLocation(property)}.</p>
                        </div>
                        <div class="col-12 mt-5 pt-lg-5 pt-3 mb-5 pb-lg-5 pb-3">
                            <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row contacto-main">
                    <div class="col-lg-5 col-12">
                        <h2>Consultanos <br class="d-none d-lg-block" /> por esta  propiedad. </h2>
                        <div class="content-contact">
                            <div class="row mb-lg-3">
                                <div class="col-lg-8">
                                    <p>Si ya tenés una propiedad en mente, no dudes en consultarnos. Con gusto responderemos todas tus dudas y te guiaremos durante el proceso.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-12 mt-4 mt-lg-0">
                        <div class="row justify-content-end">
                            <div class="col-lg-11">
                                <Formulario property={property} from={'property'} />
                                <div id="alert-message"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {(checkSimilar(property,similar_properties).length > 2 ) && 
                <div id="featured-props" className="row pt-5 similares">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <h4 class="mb-5 mt-4 similar-title">Propiedades similares</h4>
                            </div>
                        </div>
                    </div>
                    <div className="props d-flex align-items-center justify-content-center my-4">
                        <div className="container-fluid">
                            <OwlCarousel ref={refery}
                                {...option}
                                className="overflow-hidden">
                                    {checkSimilar(property,similar_properties).map((prop,index) => (
                                            <Card key={index} basic={true} property={prop}/>
                                    ))}
                            </OwlCarousel>
                        </div>
                        <div className="arrows-owl">
                            <div className="arrow" onClick={() => refery.current.prev()}> <i></i> <i></i></div>
                            <div className="arrow reverse" onClick={() => refery.current.next()}> <i></i> <i></i> </div>
                        </div>
                    </div>
                </div>}
        </section>
    )
}

export default connect(state => ({
    property: state.properties.property,
    similar_properties: state.properties.similar_properties,
    loading: state.properties.loading,
}),null)(About);